import styled from 'foundations-theming/utils';
import { getReferenceBasePadding, getReferenceVariantStyles, getTextInputHeight } from './Select.helpers';
import { deepMerge } from '../../transitional/utils/deepMerge/deepMerge';
import { JUPITER_LAYER, VENUS_LAYER } from '../../transitional/constants';
export const StyledContainer = styled.div.withConfig({
  displayName: "Selectstyles__StyledContainer",
  componentId: "bypvyj-0"
})(() => {
  return {
    inlineSize: '100%'
  };
});
export const StyledReferenceContainer = styled.div.withConfig({
  displayName: "Selectstyles__StyledReferenceContainer",
  componentId: "bypvyj-1"
})(({
  $marginStyles
}) => {
  return Object.assign({}, $marginStyles);
});
export const StyledReferenceLabelWrapper = styled.label.withConfig({
  displayName: "Selectstyles__StyledReferenceLabelWrapper",
  componentId: "bypvyj-2"
})(({
  theme: _theme
}) => {
  const theme = _theme;
  return {
    lineHeight: theme.typography['heading-50'].lineHeight,
    display: 'flex',
    justifyContent: 'space-between',
    marginBlockEnd: theme.space[100]
  };
});
export const StyledReferenceWrapper = styled.div.withConfig({
  displayName: "Selectstyles__StyledReferenceWrapper",
  componentId: "bypvyj-3"
})(({
  theme: _theme,
  $referenceSize
}) => {
  const theme = _theme;
  const blockSize = $referenceSize === 'compact' ? theme.space[800] : theme.space[1000];
  return {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    inlineSize: '100%',
    blockSize
  };
});
export const StyledReference = styled.button.withConfig({
  displayName: "Selectstyles__StyledReference",
  componentId: "bypvyj-4"
})(({
  theme: _theme,
  $referenceVariant,
  $error
}) => {
  const theme = _theme;
  const {
    paddingBlock,
    paddingInline
  } = getReferenceBasePadding(theme);
  const referenceVariantStyles = getReferenceVariantStyles(theme, $referenceVariant);
  return Object.assign({
    background: 'none',
    font: 'inherit',
    cursor: 'pointer',
    outline: 'inherit',
    position: 'relative',
    overflow: 'hidden',
    inlineSize: '100%',
    blockSize: 'inherit',
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.color['text-primary-default'],
    paddingBlock,
    paddingInline
  }, referenceVariantStyles, {
    '&:active:not(:disabled), &:focus:not(:disabled)': {
      borderColor: theme.color['border-interactive-default']
    },
    '&:disabled': {
      cursor: 'not-allowed',
      color: theme.color['text-primary-disabled']
    }
  }, $error && {
    borderColor: theme.color['border-alert-default']
  });
});
export const StyledReferenceSupportText = styled.span.withConfig({
  displayName: "Selectstyles__StyledReferenceSupportText",
  componentId: "bypvyj-5"
})(({
  theme: _theme,
  $error
}) => {
  const theme = _theme;
  return Object.assign({}, theme.typography['heading-25'], {
    display: 'block',
    color: theme.color['text-primary-subtle'],
    marginBlockStart: theme.space[100],
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }, $error && {
    color: theme.color['text-alert-default']
  });
});
export const StyledReferenceIndicator = styled.div.withConfig({
  displayName: "Selectstyles__StyledReferenceIndicator",
  componentId: "bypvyj-6"
})(({
  theme: _theme,
  $referenceSize
}) => {
  const theme = _theme;
  const {
    paddingBlock
  } = getReferenceBasePadding(theme);
  const referenceHeight = $referenceSize === 'default' ? theme.space[1000] : theme.space[800];
  return {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    blockSize: `calc(${referenceHeight} - ${paddingBlock} * 2)`,
    borderRadius: theme.borderRadius[300],
    flexShrink: 0
  };
});
export const StyledPlaceholder = styled.span.withConfig({
  displayName: "Selectstyles__StyledPlaceholder",
  componentId: "bypvyj-7"
})(({
  theme: _theme,
  $hasSelectedValues
}) => {
  const theme = _theme;
  return {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    marginInlineEnd: $hasSelectedValues ? theme.space[600] : theme.space[200]
  };
});
export const StyledDownCaratIconWrapper = styled.span.withConfig({
  displayName: "Selectstyles__StyledDownCaratIconWrapper",
  componentId: "bypvyj-8"
})(({
  $size,
  $selectStylesOverrides = {}
}) => {
  return deepMerge({
    display: 'flex',
    alignItems: 'center',
    opacity: 0.8,
    inlineSize: $size,
    blockSize: $size
  }, $selectStylesOverrides);
});
export const StyledRemoveIconWrapper = styled.span.withConfig({
  displayName: "Selectstyles__StyledRemoveIconWrapper",
  componentId: "bypvyj-9"
})(({
  theme: _theme,
  $size
}) => {
  const theme = _theme;
  return {
    all: 'unset',
    position: 'absolute',
    insetInlineEnd: '8px',
    insetBlockStart: '50%',
    transform: 'translateY(-50%)',
    inlineSize: $size,
    blockSize: $size,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: theme.space[100],
    border: `${theme.space[25]} solid transparent`,
    '&:focus-visible': {
      borderColor: theme.color['border-interactive-default'],
      borderRadius: theme.borderRadius[300]
    }
  };
});
export const StyledTextInputWrapper = styled.div.withConfig({
  displayName: "Selectstyles__StyledTextInputWrapper",
  componentId: "bypvyj-10"
})(({
  theme: _theme,
  $iconSize
}) => {
  const theme = _theme;
  return {
    position: 'absolute',
    insetBlockStart: 0,
    insetInlineStart: 0,
    insetInlineEnd: 0,
    zIndex: VENUS_LAYER,
    inlineSize: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: theme.space[200],
    backgroundColor: theme.color['fill-surface-default'],
    blockSize: getTextInputHeight(theme),
    borderBlockEnd: `${theme.space[25]} solid ${theme.color['border-primary-default']}`,
    color: theme.color['text-primary-default'],
    '& svg': {
      inlineSize: $iconSize,
      blockSize: $iconSize,
      marginInlineStart: theme.space[300]
    }
  };
});
export const StyledTextInput = styled.input.withConfig({
  displayName: "Selectstyles__StyledTextInput",
  componentId: "bypvyj-11"
})(({
  theme: _theme
}) => {
  const theme = _theme;
  return {
    inlineSize: '100%',
    paddingBlock: theme.space[200],
    paddingInlineEnd: theme.space[200],
    border: 'none',
    outline: 'none',
    fontSize: 'inherit',
    backgroundColor: theme.color['fill-surface-default'],
    '&::placeholder': {
      color: theme.color['text-primary-subtle'],
      fontFamily: theme.typography['body-100'].fontFamily
    }
  };
});
export const StyledMenu = styled.div.withConfig({
  displayName: "Selectstyles__StyledMenu",
  componentId: "bypvyj-12"
})(({
  theme: _theme,
  $isReferenceHidden
}) => {
  const theme = _theme;
  return {
    visibility: $isReferenceHidden ? 'hidden' : 'visible',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    outline: 0,
    backgroundColor: theme.color['fill-surface-default'],
    borderRadius: theme.borderRadius[200],
    boxShadow: theme.boxShadow[300],
    zIndex: JUPITER_LAYER
  };
});
export const StyledMenuOptionsWrapper = styled.div.withConfig({
  displayName: "Selectstyles__StyledMenuOptionsWrapper",
  componentId: "bypvyj-13"
})(({
  theme: _theme,
  $searchable
}) => {
  const theme = _theme;
  return Object.assign({
    borderRadius: 'inherit',
    flexGrow: 1,
    overflowY: 'auto',
    scrollbarWidth: 'thin',
    scrollbarColor: `${theme.color['fill-tertiary-pressed']} transparent`
  }, $searchable && {
    marginBlockStart: getTextInputHeight(theme)
  });
});
export const StyledMenuOption = styled.div.withConfig({
  displayName: "Selectstyles__StyledMenuOption",
  componentId: "bypvyj-14"
})(({
  theme: _theme,
  $isActive,
  $disabled,
  $isSubheading,
  $divider
}) => {
  const theme = _theme;
  return Object.assign({
    paddingBlock: theme.space[300],
    paddingInline: theme.space[400],
    cursor: 'pointer',
    outline: 0
  }, $isSubheading && {
    padding: theme.space[200]
  }, $isActive && {
    backgroundColor: theme.color['fill-secondary-subtle']
  }, $disabled && {
    cursor: 'not-allowed'
  }, !$disabled && {
    '&:active': {
      backgroundColor: theme.color['fill-tertiary-hover']
    }
  }, $divider && {
    borderBlockEndStyle: 'solid',
    borderBlockEndWidth: theme.space[25],
    borderBlockEndColor: theme.color['border-primary-subtle']
  });
});
export const StyledMenuOptionContentWrapper = styled.div.withConfig({
  displayName: "Selectstyles__StyledMenuOptionContentWrapper",
  componentId: "bypvyj-15"
})(({
  theme: _theme,
  $optionTextColor
}) => {
  const theme = _theme;
  return {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    color: theme.color[$optionTextColor]
  };
});
export const StyledMenuOptionIndicator = styled.div.withConfig({
  displayName: "Selectstyles__StyledMenuOptionIndicator",
  componentId: "bypvyj-16"
})(({
  theme: _theme
}) => {
  const theme = _theme;
  return {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    blockSize: theme.space[600],
    borderRadius: theme.borderRadius[300]
  };
});
export const StyledHighlightText = styled.span.withConfig({
  displayName: "Selectstyles__StyledHighlightText",
  componentId: "bypvyj-17"
})(({
  theme: _theme
}) => {
  const theme = _theme;
  return {
    color: theme.color['text-interactive-default']
  };
});